/*
 * This file is copy of /www/src/components/theme.scss
 */

$backgound: #ffffff;
$baseColor: #0a6cff;
$subColor1: #e9f2ff;
$subColor2: #aaccff;
$subColor3: #194b97;
$subColor4: #bbffaa;
$subColor5: #ff6a2a;
$subColor6: #0c244a;

// dark theme
// $backgound: #000000;
// $baseColor: #0a6cff;
// $subColor1: #0c244a;
// $subColor2: #194b97;
// $subColor3: #aaccff;
// $subColor4: #bbffaa;
// $subColor5: #ff6a2a;
// $subColor6: #e9f2ff;

// 基本色: 線画やフォントとして
$main-color: $baseColor;

// 背景色: 一番シンプルなページはこの背景にfont-color-deepの文字のみ
$background-color: $backgound;
// 背景色2: 色分けする場合
$block-color-deep: $baseColor;
// 背景色3: 色分けする場合
$block-color-light: $subColor1;
// 背景色フッター
$footer-color: $subColor3;

// 文字色: 背景に応じて選択
$font-color: $subColor6;
$font-color-light: $backgound;
$font-color-deep: $baseColor;
// 文字色フッター
$font-color-footer: $subColor1;

// アイコン1
$icon-color1: $baseColor;
// アイコン2
$icon-color2: $subColor4;
// アイコン3
$icon-color3: $subColor5;
// 強調色1
$strong-color1: $subColor3;
// 強調色2
$strong-color2: $subColor5;
// リンク色（青）
$link-color: $baseColor;

// 文字の統一
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 100;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 200;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 300;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 400;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Bold');
  font-weight: bold;
}

#___gatsby {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Hiragino Sans', 'Noto Sans CJK JP', 'Original Yu Gothic', 'Yu Gothic',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Sans Emoji';
  background-color: $backgound;
}

#___gatsby {
  color: $font-color;
  font-size: 15px;

  // 構造を作るタグのmargin/padding/widthなど
  // sectionのmarginはtop方向にのみ。基本的に中の要素がmarginを持つようにするが、padding-bottomは親子関係の相殺を利用するのが簡単なので
  main {
    min-height: calc(100vh - 225px);

    $section-horizonal-padding: 16px;
    article > div > section {
      padding: 24px $section-horizonal-padding 16px $section-horizonal-padding;
      & > * {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        font-weight: normal;
      }
      section {
        padding-top: 24px;
      }
    }
  }

  // text
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $main-color;
    font-weight: normal;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  h2 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
  }

  h3 {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: bold;
  }

  h4,
  h5,
  h6 {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    margin-top: 8px;
    margin-bottom: 12px;
    line-height: 175%;
  }

  main li {
    margin: 12px 0 12px 12px;
    padding-left: 8px;
    position: relative;
    & > p {
      margin: 4px;
    }
    ul,
    ol {
      margin: 4px;
      padding: 4px;
      border: none;
    }
  }

  main ul,
  main ol,
  .attention {
    display: block;
    margin: 16px 0;
    padding: 16px 24px;
    background-color: $block-color-light;
    border-radius: 2px;
    border: 2px solid $main-color;
    a {
      color: $link-color;
    }
  }

  main ul {
    // 独自の■表示
    li {
      list-style: none;
    }
    li::before {
      content: '';
      border-left-width: 12px;
      border-left-style: solid;
      border-left-color: $baseColor;
      position: absolute;
      height: 12px;
      left: -10px;
      top: 4px;
    }
    li li::before {
      border-left-color: $subColor2;
    }
    li li li::before {
      border-left-color: $subColor4;
    }
  }

  main.ol {
    counter-reset: number;
    // 独自の①表記
    li {
      list-style: none;
      margin-bottom: 0.4em;
      &:before {
        display: inline-block;
        position: absolute;
        top: 1px;
        left: -16px;
        width: 20px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        counter-increment: number;
        content: counter(number);
        background-color: $baseColor;
        border-width: 1px;
        border-color: $baseColor;
        border-style: solid;
        border-radius: 50%;
        color: $backgound;
        a {
          color: $link-color;
        }
      }
    }
    li li::before {
      background-color: $subColor1;
      color: $main-color;
      border-color: $main-color;
    }
    li li li::before {
      background-color: $subColor1;
      color: $subColor6;
      border-color: $subColor6;
    }
  }

  dl {
    display: block;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  dt {
    display: block;
    margin-top: 16px;
    margin-bottom: 8px;
    color: $baseColor;
    font-weight: 600;
  }

  dd {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 32px;
  }

  time {
    color: $strong-color1;
    display: inline-block;
    padding: 0 8px;
  }

  blockquote {
    margin: 24px 0;
    padding: 30px 16px 16px 16px;
    background-color: $block-color-light;
    color: $strong-color1;
    position: relative;
    & > p {
      color: $strong-color1;
    }
    &::before {
      content: '"';
      font-family: cursive;
      font-size: 400%;
      position: absolute;
      top: -18px;
      left: 0;
    }
  }

  em {
    color: $strong-color1;
    font-weight: bold;
    background: linear-gradient(transparent 70%, $subColor4 70%);
    font-style: normal;
  }

  strong {
    color: $strong-color2;
    font-weight: bold;
    background: linear-gradient(transparent 90%, $strong-color2 90%);
    font-style: normal;
    em {
      color: $strong-color2;
      background: linear-gradient(transparent 90%, $strong-color2 90%);
      font-weight: bold;
      font-size: 120%;
    }
  }

  hr {
    border: 2px solid $baseColor;
    margin: 20px 0;
  }

  a {
    color: $link-color;
  }

  pre {
    background-color: $subColor1;
    padding: 16px;
    white-space: pre-wrap;
  }

  // 左右にscrollするテーブルのためのクラス
  .scroll {
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 32px;
    margin-bottom: 32px;
    & > table {
      margin: 0 0 4px 0;
    }
  }
  .scroll::-webkit-scrollbar {
    height: 6px;
  }
  .scroll::-webkit-scrollbar-track {
    background: $subColor2;
  }
  .scroll::-webkit-scrollbar-thumb {
    background: $subColor3;
  }
  table {
    width: 100%;
    margin: 32px auto;
    border: none;
    background-color: $background-color;
  }

  th {
    padding: 8px 16px;
    background-color: $block-color-deep;
    color: $font-color-light;
  }

  tr:nth-child(even) {
    background-color: $block-color-light;
  }

  td {
    padding: 8px 16px;
  }

  // 共通コンポーネント
  .button > a {
    display: table; // 中央寄せするためのhack
    margin: 24px auto;
    padding: 12px 40px;
    background-color: $subColor3;
    border: 2px solid $subColor1;
    border-radius: 2px;
    color: $font-color-light;
    text-decoration: none;
    transition: all 0.2s ease;
    font-weight: 600;
    &:hover {
      border-color: $subColor2;
      background-color: $main-color;
    }
  }

  main header {
    display: block;
    background-color: $main-color;
    padding: 32px 8px;
    h1 {
      color: $font-color-light;
      font-weight: 500;
    }
    time {
      display: block;
      text-align: right;
      color: $font-color-light;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      font-weight: normal;
    }
  }

  $headerHeight: 45px;
  .dummy-header {
    height: $headerHeight;
  }
  header.header {
    position: fixed;
    transition: all 0.5s ease;
    margin: 0;
    height: $headerHeight;
    z-index: 99;
    width: 100%;
    background-color: $backgound;
    top: 0;
    &.hidden {
      top: -60px;
    }
  }

  .top-content-container {
    position: relative;
    margin-bottom: -3px;
    height: calc(100vh - 42px - 32px); // header and bottom
    width: 100%;
    display: block;
    margin: 0 auto;
    .top-img-area {
      height: 100%;
      background-size: cover;
      background-position: center;
      img.watcher {
        position: absolute;
        bottom: -6px;
        left: 66%;
        height: 33%;
      }
    }
    // top page eye catch image
    .top-message {
      position: absolute;
      top: 0px;
      margin: 24px;
      line-height: 72px;
      & > div > span {
        color: $main-color;
        background-color: rgba($backgound, 0.8);
        font-size: 36px;
      }
    }
  }

  // top page section
  .main-content > div {
    & > section:nth-child(odd) {
      background-color: $background-color;
      & {
        a,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $main-color;
        }
        .button > a {
          color: $font-color-light;
        }
      }
    }

    & > section:nth-child(even) {
      background-color: $block-color-deep;
      & {
        p,
        a,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $font-color-light;
        }
        .button > a {
          color: $font-color-light;
        }
        ul a {
          color: $link-color;
        }
      }
    }

    .top-page-infos .top-page-info {
      border: 1px solid $baseColor;
      border-radius: 4px;
      padding: 16px;
      margin-top: 32px;

      header {
        background-color: $background-color;
        padding: 4px 0;
        h2 {
          color: $font-color-deep;
          display: inline;
        }
        time {
          color: $font-color-deep;
          display: inline;
          padding: 4px;
        }
      }
    }
  }

  footer {
    background-color: $footer-color;
    color: $font-color-footer;
    a {
      color: $font-color-footer;
    }
    padding: 16px 8px;
    & > .credits {
      color: $font-color-footer;
      text-align: center;
    }
  }

  footer nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 8px auto;
    & ul {
      margin: 8px;
      list-style: none;
      & li {
        color: $subColor1;
        min-width: 200px;
        padding: 4px 0;
        & a {
          color: $subColor1;
        }
      }
    }
  }

  article > div > section:nth-of-type(even) {
    // todo 透明度で無理やり作るのではなくきちんと色を定義する
    background-color: rgba($subColor3, 0.03);
  }

  // cards content
  $pc: 1024px; // PC

  @mixin pc {
    @media (min-width: ($pc)) {
      @content;
    }
  }

  @mixin mobile {
    @media (max-width: ($pc)) {
      @content;
    }
  }

  article.cards {
    section {
      margin-top: 0;
      margin-bottom: 0;
      border-top: 2px solid $main-color;
      h2 {
        text-align: center;
        padding: 8px;
      }
      section {
        border-top: 1px solid $subColor1;
      }
    }
    @include mobile {
      section {
        padding: 16px 8px;
      }
    }
    @include pc {
      section {
        padding: 24px 16px;
        & > div:first-of-type {
          margin-bottom: 32px;
        }
      }
    }
  }

  // image
  img.large {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 32px auto;
  }

  div.sizedImg .gatsby-resp-image-wrapper .gatsby-resp-image-image {
    width: auto;
    height: auto;
  }
}
